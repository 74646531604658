import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const TablePagination = ({
  pageChangeHandler,
  totalRows,
  rowsPerPage,
  searchText,
  setCurrent,
}) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(1);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // These variables give the first and last record/row number
  // with respect to the current page
  const [pageFirstRecord, setPageFirstRecord] = useState(1);
  const [pageLastRecord, setPageLastRecord] = useState(rowsPerPage);

  // Onclick handlers for the buttons
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (data) => {
    setCurrentPage(data.selected + 1);
  };

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
   if (setCurrent) setCurrent(1);
  }, [searchText]);

  // To set the starting index of the page
  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;

    // Some APIs require skip for paginaiton. If needed use that instead
    pageChangeHandler(skipFactor);
    // pageChangeHandler(currentPage);
    setPageFirstRecord(skipFactor + 1);
  }, [currentPage]);

  // To set the last index of the page
  useEffect(() => {
    const count = pageFirstRecord + rowsPerPage;
    setPageLastRecord(count > totalRows ? totalRows : count - 1);
  }, [pageFirstRecord, rowsPerPage, totalRows]);

  return (
    <div className="pagination-responsive pagination-wrapper">
      <div className="page-counter">
        <span>{`Showing ${pageFirstRecord} - ${pageLastRecord} of ${totalRows}`}</span>
      </div>

      <Pagination>
        <ReactPaginate
          previousLabel={
            <div onClick={onPrevPage} disabled={!canGoBack}>
              Previous
            </div>
          }
          nextLabel={
            <div onClick={onNextPage} disabled={!canGoNext}>
              Next
            </div>
          }
          breakLabel={"..."}
          pageCount={noOfPages}
          marginPageDisplayed={5}
          pageRangeDisplayed={5}
          onPageChange={onPageSelect}
          containerClassName={"pagination"}
          pageClassName={"page-item page-number"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item action-button"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item action-button"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          forcePage={currentPage - 1}
        />
      </Pagination>
    </div>
  );
};

export default TablePagination;
