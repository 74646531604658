import moment from "moment";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/layout";
import PostCard from "../components/posts/PostCard";
import PostsController from "../lib/posts/postsController";
import serializable from "../lib/serializable";
import ServerProps from "../lib/serverProps";
import TablePagination from "../components/TablePagination";
import { useRouter } from "next/router";
import Constants from "../lib/constants";

export const NewsPub = ({ post }) => <PostCard.News post={post} />;

const NewsPage = ({ state, posts, postTypes, postCount, showHeroImage }) => {
  const [postType, setPostType] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [skipFactor, setSkipFactor] = useState(0);
  // const [defaultPaginationPage, setDefaultPaginationPage] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (postType) {
      router.replace({ query: { ...router.query, type: postType, skip: 0 } });
    } else {
      const { type, ...others } = router.query;
      removeParamFromRouter(others);
    }
  }, [postType]);

  let postSet = posts;

  // most recently created post.
  let recentlyPublishedPost = null;

  if (postSet.length > 0) {
    // sorting post by created date and time and get most recently created post.
    const sortedPublishedPost = postSet?.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    //recently published post
    recentlyPublishedPost = sortedPublishedPost[0];

    //other published posts.
    postSet = sortedPublishedPost.slice(1);
  }

  const onPostPageChange = (postNumber) => {
    if (postNumber !== skipFactor) {
      router.replace({ query: { ...router.query, skip: postNumber } });
    } else {
      const { skip, ...others } = router.query;
      removeParamFromRouter(others);
    }
  };

  const removeParamFromRouter = (others) => {
    router.replace({ query: others });
  };

  return (
    <Layout state={state}>
      <div className="offset-header page-news">
        <Container>
          <Row>
            <Col>
              <h1 className="title-2">News</h1>
              {/* <Subscribe user={state.user} /> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="my-3 page-subtitle-area">
                <h3 className="title-3 text-right">
                  {/* default All option */}
                  <span
                    className="m-item"
                    key={0}
                    onClick={() => setPostType(null)}
                    style={{
                      color: postType === null ? "#CC744C" : "#000",
                      cursor: "pointer",
                    }}
                  >
                    All
                  </span>

                  {/* other filtering options */}
                  {postTypes.map((type) => (
                    <span
                      className="m-item cursor-pointer"
                      key={type?.id}
                      onClick={() => setPostType(type?.id)}
                      style={{
                        color: postType === type?.id ? "#CC744C" : "#000",
                        cursor: "pointer",
                      }}
                    >
                      {type?.name}
                    </span>
                  ))}
                </h3>
              </div>
            </Col>
          </Row>
          {showHeroImage && recentlyPublishedPost && (
            <>
              <Row className="latest">
                <Col lg={8} sm={12} className="banner-image-content">
                  <div className="banner-image-wrapper">
                    <Link
                      href={`/post/${
                        recentlyPublishedPost?.slug &&
                        recentlyPublishedPost.slug
                      }`}
                      className="mt-5"
                    >
                      <img
                        src={
                          recentlyPublishedPost.coverImage?.image
                            ? recentlyPublishedPost.coverImage.image
                            : Constants.newsDefaultImage
                        }
                        alt="banner-image"
                        className="banner-image img-fluid desktop-view"
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg={4} sm={12}>
                  <h2 className="new-main-title">
                    {recentlyPublishedPost?.name}
                  </h2>
                  <div className="bt-detail">
                    <h4 className="news-card-subtitle">
                      {/* Posted{" "} */}
                      {recentlyPublishedPost.updatedAt
                        ? moment(recentlyPublishedPost.updatedAt).format(
                            "MMM Do YY"
                          )
                        : null}
                      <span className="author">
                        &nbsp; by
                        {/* <UserAvatar user={postData.User} /> */}{" "}
                        {recentlyPublishedPost.User?.name || "author"}
                      </span>
                    </h4>
                    <p className="news-short">
                      {recentlyPublishedPost?.description}
                    </p>
                    <Link
                      href={`/post/${recentlyPublishedPost?.slug}`}
                      className="mt-5"
                    >
                      <a variant="link" className="news-card-link">
                        Read More
                      </a>
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="col-12">
                  <hr />
                </div>
              </Row>
            </>
          )}
          {/* <Row>
          <Col>
            <Post.List posts={posts} state={state} />
          </Col>
        </Row> */}
          {/* <Row className="home-news-content">
          {posts.map((post) => (
            <Col lg={4} md={4} sm={6} xs={12} key={post.id} className="row-eq-height">
              <div className="home-news-wrapper">
                <NewsPub post={post}/>
              </div>
            </Col>
          </Row>
          <Row className="latest">
            <Col lg={6} sm={12} className="banner-image-content">
              <div className="banner-image-wrapper">
                <img
                  src="static/Banner-img.png"
                  alt="banner-image"
                  className="banner-image img-fluid desktop-view"
                />
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <h2>How to build a pitch deck – suggestions of an investor</h2>
              <div className="bt-detail">
                <h4 className="news-card-subtitle">
                  Sep 30th 22 by Stefan Zurflueh
                </h4>
                <p className="news-short">
                  If you have a business and ever thought of getting external
                  funding, you know that investors will ask for a pitch deck or
                  slide deck.
                </p>
                <Link href="/#">Read More</Link>
              </div>
            </Col>
          </Row>
          <hr />

          {/* <Row>
        <Col>
          <Post.List posts={posts} state={state} />
        </Col>
      </Row> */}
          {/* <Row className="home-news-content">
        {posts.map((post) => (
          <Col lg={4} md={4} sm={6} xs={12} key={post.id} className="row-eq-height">
            <div className="home-news-wrapper">
              <NewsPub post={post}/>
            </div>
          </Col>
        ))}
      </Row> */}

          <Row>
            <Col className="home-news-content">
              {postSet.map((post) => (
                <div key={post.id} className="row-eq-height">
                  <div className="home-news-wrapper">
                    <NewsPub post={post} />
                  </div>
                </div>
              ))}
            </Col>
          </Row>

          {/* show pagination if only has posts to show */}
          {postCount > 0 && (
            <Row className="news-pagi">
              <TablePagination
                pageChangeHandler={onPostPageChange}
                rowsPerPage={13}
                totalRows={postCount}
                setCurrent={setCurrentPage}
                key={postCount}
              />
            </Row>
          )}

          {/* <Row size="xl" className="home-news-content">
        <div className="home-news-wrapper">
          <Post.List posts={posts} state={state} />
        </div>
      </Row> */}
        </Container>
      </div>
    </Layout>
  );
};

export async function getServerSideProps({ query, req, res }) {
  let posts = [];
  let postCount = 0;
  const skip = query.skip ? parseInt(query.skip) : 0;
  const postByTypeFilter = [{ status: "published", PostTypeId: query.type }];

  const postTypes = await PostsController.getPostSTypes();

  if (query.type) {
    posts = await PostsController.getPostsByType(postByTypeFilter, skip);
    postCount = await PostsController.getPostCountByPostType(postByTypeFilter);
  } else {
    posts = await PostsController.getPosts([{ status: "published" }], skip, 13);
    postCount = await PostsController.getPostCount([{ status: "published" }]);
  }

  return {
    props: {
      state: await ServerProps.getState(req),
      posts: serializable(posts),
      postTypes: serializable(postTypes),
      postCount: postCount,
      postsByType: serializable(posts),
      showHeroImage: parseInt(query.skip) == 0 || !query.skip,
    },
  };
}

export default NewsPage;
