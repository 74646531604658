import React, { useState } from "react";
import pluralize from "pluralize";
import { Card, Container, Button, Row, Col, Image } from "react-bootstrap";
import Link from "next/link";
import moment from "moment";
import Bg from "../../utils/imageBackgrounds";
import UserAvatar from "../users/UserAvatar";
import Icons from "../icons";
import PostLikes from "./PostLike";
import Status from "../Status";
import IconButton from "../IconButton";
import Api from "../../utils/apiCall";
import { useRouter } from "next/router";
import Constants from "../../lib/constants";

const truncate = (str, num) => {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
};

const PostCard = ({ post, state }) => {
  const { colors } = post;
  return (
    <>
      <Col>
        <Card className="news-card">
          <div className="news-card-img-wrapper">
            {post.coverImage?.thumb ? (
              <Image src={post.coverImage.thumb} className="news-card-image" />
            ) : null}
          </div>
          <Card.Body className="news-card-body">
            <Card.Title>
              <div>
                <h2 className="news-card-title">{post.name}</h2>
              </div>
              <div>
                <h4 className="news-card-subtitle">
                  {post.publishDate
                    ? moment(post.publishDate).format("MMM Do YY")
                    : null}
                  &nbsp;by {post.User?.name || "author"}
                </h4>
              </div>
            </Card.Title>
            <Card.Text>
              <div className="news-card-text">
                {truncate(post.description, 200)}...
              </div>
            </Card.Text>
            <Link href={`/post/${post.slug}`}>
              <a variant="link" className="news-card-link">
                Read More
              </a>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

const Compact = ({ post, state }) => {
  const router = useRouter();
  const deletePost = async (id) => {
    await Api.call("post", "DELETE", { id });
    router.reload();
  };
  return (
    <Card className="article-card">
      <Card.Body className="article-card-body">
        <Row>
          <Col md={12}>
            {post.coverImage?.thumb ? (
              <Image src={post.coverImage.thumb} fluid />
            ) : null}
          </Col>
          <Col>
            <Card.Title className="mb-0">
              <Link href={`/post/${post.slug}`}>
                <a className="mt-0">{post.name}</a>
              </Link>
            </Card.Title>
            <Status status={post.status} />
            <Card.Text className="article-card-subtitle">
              {post.publishDate
                ? moment(post.publishDate).format("MMM Do YY")
                : null}
              &nbsp;by <UserAvatar user={post.User} />{" "}
              {post.User?.name || "author"}
            </Card.Text>
            {/* { post.status === 'published' ? (
            <Row>
              <Col>
                <PostLikes post={post} user={state?.user} />&nbsp;
                <Link href={`/post/${post.slug}#comments`}>
                  <a><Icons.Chat /> {post.comments} {pluralize('comment', post.comments)}</a>
                </Link>
              </Col>
              <Col>
                <IconButton icon="eye" href={`/post/${post.slug}`}>View</IconButton>
              </Col>
            </Row>
          ) : (
            <>
              <IconButton href={`/post/${post.slug}`}>Edit</IconButton>
              <IconButton icon="trash" onClick={() => deletePost(post.id)}>Delete</IconButton>
            </>
          )} */}

            <div className="article-card-text">
              {truncate(post.description, 200)}...
            </div>
            <Link href={`/post/${post.slug}`}>
              <a variant="link" className="article-card-link">
                Read More
              </a>
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

//to news page

const News = ({ post, state }) => {
  return (
    <Card className="news-card">
      <div className="news-card-img-wrapper">
        <Link href={`/post/${post.slug}`} className="mt-5">
          <Image
            src={
              post?.coverImage?.thumb != null
                ? post.coverImage.thumb
                : Constants.newsDefaultImage
            }
            className="news-card-image"
          />
        </Link>
      </div>
      <Card.Body className="news-card-body">
        <Card.Title>
          <div>
            <h2 className="news-card-title">{post.name}</h2>
          </div>
        </Card.Title>
        <Card.Text>
          <div className="wrap-in">
            <div>
              <h4 className="news-card-subtitle">
                {post.createdAt
                  ? moment(post.updatedAt).format("MMM Do YY")
                  : null}
                &nbsp;by {post.User?.name || "author"}
              </h4>
            </div>
            <div className="news-card-text descript-news">
              {truncate(post.description, 200)}...
            </div>
            <Link href={`/post/${post.slug}`} className="mt-5">
              <a variant="link" className="news-card-link">
                Read More
              </a>
            </Link>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

PostCard.News = News;
PostCard.Compact = Compact;
export default PostCard;
