const Constants = {
  status: {
    OFFER_SUCCESS: "OFFER_SUCCESS",
    CONSTITUTION_SUCCESS: "CONSTITUTION_SUCCESS",
    SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_SUCCESS",
    ERROR: "ERROR",
    CANCEL: "CANCEL",
    REMOVE: "REMOVE",
    WARNING: "WARNING",
    COOLOFF: "COOLOFF",
  },
  alertMsg: {
    OFFER_SUCCESS: "Offer document uploaded successfully.",
    CONSTITUTION_SUCCESS: "Constitution Document uploaded successfully.",
    SUBSCRIPTION_SUCCESS: "Subscription Document uploaded successfully.",
    ERROR: "Upload Error!!",
    CANCEL: "You have unsaved changes.",
    REMOVE: "The file has been removed successfully.",
    WARNING: "Offer Document need to be uploaded!",
    COOLOFF:
      "There is a retail user who have made a payment within last 5 days, hence offer cannot be closed",
    PRIVATE_OFFER_LOADING_FAILED: "Failed to load offer ",
  },

  //Green Id verification status returned
  greenIdStates: {
    SUCCESS: {
      VERIFIED: "VERIFIED",
      VERIFIED_ADMIN: "VERIFIED_ADMIN",
      VERIFIED_WITH_CHANGES: "VERIFIED_WITH_CHANGES",
    },
    OTHER: {
      PENDING: "PENDING",
      LOCKED_OUT: "LOCKED_OUT",
      ERROR: "ERROR",
      ACTION_REQUIRED: "ACTION_REQUIRED",
    },
    CONTINUE: {
      IN_PROGRESS: "IN_PROGRESS",
    },
  },
  streetTypes: [
    { name: "AVE", value: "Avenue" },
    { name: "CIR", value: "Circle" },
    { name: "CCT", value: "Circuit" },
    { name: "CL", value: "Close" },
    { name: "CT", value: "Court" },
    { name: "CRES", value: "Crescent" },
    { name: "DR", value: "Drive" },
    { name: "ESP", value: "Esplanade" },
    { name: "EXP", value: "Expressway" },
    { name: "HWY", value: "Highway" },
    { name: "LANE", value: "Lane" },
    { name: "MWY", value: "Motorway" },
    { name: "PDE", value: "Parade" },
    { name: "PL", value: "Place" },
    { name: "RD", value: "Road" },
    { name: "SQ", value: "Square" },
    { name: "ST", value: "Street" },
    { name: "TCE", value: "Terrace" },
    { name: "WAY", value: "Way" },
  ],
  states: [
    { name: "NSW", value: "NSW" },
    { name: "VIC", value: "VIC" },
    { name: "QLD", value: "QLD" },
    { name: "SA", value: "SA" },
    { name: "WA", value: "WA" },
    { name: "ACT", value: "ACT" },
    { name: "NT", value: "NT" },
    { name: "TAS", value: "TAS" },
  ],
  slugs: {
    SIGNUP_SUCCESS: "signup-success",
    SIGNUP_PROCESS: "signup-process",
    RESETPASSWORD: "resetpassword",
  },

  newsDefaultImage:
    "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default-cover.svg",

  paymentOptions: {
    DIRECT_DEBIT: "direct-debit",
    BANK_TRANSFER: "bank-transfer",
  },

  accountTypes: [
    { name: "savings", value: "Savings" },
    { name: "checking", value: "Checking" },
  ],

  holderTypes: [
    { name: "personal", value: "Personal" },
    { name: "business", value: "Business" },
  ],

  traansactionStatus: {
    EXPORT: "export",
    PENDING: "pending",
    CONFIRM: "confirmed",
    PAYMENT_PENDING: "payment_pending",
    REFUND_PENDING: "refund_pending",
    REFUND_REQUESTED: "refund requested",
    BATCHED: "batched",
    BANK_PROCESSING: "bank_processing",
    SUCCESSFUL: "successful",
    INVALID_ACCOUNT_DETAILS: "invalid_account_details",
    FAILED_DIRECT_DEBIT: "failed_direct_debit",
    REFUNDED: "refunded",
    COMPLETED: "completed",
    PAYMENT_UNSUCCESSFUL: "payment_unsuccessful",
  },

  DDfailureReason: {
    failed_direct_debit:
      "The Batch Transaction has been dishonoured due to insufficient funds or other restrictions. Please refer user to contact their financial institution.",
    invalid_account_details:
      "The Batch Transaction has failed due to incorrect bank account details.",
  },

  wholesaleStatus: {
    PENDING: "pending",
    REFUSED: "refused",
    SUCCESS: "success",
  },
};

export default Constants;
